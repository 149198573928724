var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.reader.js");
'use strict';var kz=function(a,b,c,d,e){this.ab=a;this.ra=b;this.K=c;this.Na=d;this.J=e;this.C=2565220111;this.I=0},lz=new $CLJS.M("tailrecursion.priority-map","not-found","tailrecursion.priority-map/not-found",-436727517);$CLJS.g=kz.prototype;$CLJS.g.na=function(a,b){return $CLJS.J.g(this.ra,b)};$CLJS.g.$=function(a,b,c){return $CLJS.J.j(this.ra,b,c)};$CLJS.g.da=function(a,b,c){return $CLJS.ih(b,function(d){return $CLJS.ih(b,$CLJS.lh,""," ","",c,d)},"#tailrecursion.priority-map {",", ","}",c,this)};$CLJS.g.O=function(){return this.K};$CLJS.g.ha=function(){return $CLJS.D(this.ra)};
$CLJS.g.pc=function(){if(0===$CLJS.D(this.ra))return null;var a=$CLJS.z(this.ab),b=$CLJS.z($CLJS.Cb(a));return $CLJS.n(this.Na)?new $CLJS.P(null,2,5,$CLJS.Q,[b,this.ra.h?this.ra.h(b):this.ra.call(null,b)],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Bb(a)],null)};
$CLJS.g.qc=function(){if(0===$CLJS.D(this.ra))throw Error("Can't pop empty priority map");var a=$CLJS.z(this.ab),b=$CLJS.Cb(a),c=$CLJS.z(b);a=$CLJS.Bb(a);return $CLJS.E.g($CLJS.D(b),1)?new kz($CLJS.Mj.g(this.ab,a),$CLJS.Mj.g(this.ra,c),this.K,this.Na,null):new kz($CLJS.R.j(this.ab,a,$CLJS.Nj.g(b,c)),$CLJS.Mj.g(this.ra,c),this.K,this.Na,null)};
$CLJS.g.oc=function(){var a=this,b=this;return $CLJS.n(a.Na)?$CLJS.y(function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,A,C,G,K,S){return function da(T){return new $CLJS.ie(null,function(){return function(){for(;;){var oa=$CLJS.y(T);if(oa){if($CLJS.vd(oa)){var Ma=$CLJS.ic(oa),sb=$CLJS.D(Ma),Qa=$CLJS.le(sb);a:for(var Xa=0;;)if(Xa<sb){var Ja=$CLJS.hd(Ma,Xa);Ja=
new $CLJS.P(null,2,5,$CLJS.Q,[Ja,a.ra.h?a.ra.h(Ja):a.ra.call(null,Ja)],null);Qa.add(Ja);Xa+=1}else{Ma=!0;break a}return Ma?$CLJS.oe($CLJS.qe(Qa),da($CLJS.jc(oa))):$CLJS.oe($CLJS.qe(Qa),null)}Qa=$CLJS.z(oa);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[Qa,a.ra.h?a.ra.h(Qa):a.ra.call(null,Qa)],null),da($CLJS.Hc(oa)))}return null}}}(v,x,A,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.Xb(a.ab))}()):$CLJS.y(function(){return function e(d){return new $CLJS.ie(null,
function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,A,C,G,K,S){return function da(T){return new $CLJS.ie(null,function(oa,Ma,sb){return function(){for(;;){var Qa=$CLJS.y(T);if(Qa){if($CLJS.vd(Qa)){var Xa=$CLJS.ic(Qa),Ja=$CLJS.D(Xa),Ua=$CLJS.le(Ja);a:for(var Ta=0;;)if(Ta<Ja){var mb=$CLJS.hd(Xa,Ta);Ua.add(new $CLJS.P(null,2,5,$CLJS.Q,[mb,sb],null));Ta+=1}else{Xa=!0;break a}return Xa?$CLJS.oe($CLJS.qe(Ua),da($CLJS.jc(Qa))):
$CLJS.oe($CLJS.qe(Ua),null)}Ua=$CLJS.z(Qa);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[Ua,sb],null),da($CLJS.Hc(Qa)))}return null}}}(v,x,A,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.Xb(a.ab))}())};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=$CLJS.Mc(this)};$CLJS.g.V=function(a,b){return $CLJS.Pb(this.ra,b)};$CLJS.g.oa=function(){return $CLJS.kd($CLJS.mz,this.K)};
$CLJS.g.Gb=function(a,b){a=this.ra.g?this.ra.g(b,lz):this.ra.call(null,b,lz);if($CLJS.E.g(a,lz))return this;a=this.Na.h?this.Na.h(a):this.Na.call(null,a);var c=this.ab.h?this.ab.h(a):this.ab.call(null,a);return $CLJS.E.g($CLJS.D(c),1)?new kz($CLJS.Mj.g(this.ab,a),$CLJS.Mj.g(this.ra,b),this.K,this.Na,null):new kz($CLJS.R.j(this.ab,a,$CLJS.Nj.g(c,b)),$CLJS.Mj.g(this.ra,b),this.K,this.Na,null)};
$CLJS.g.ma=function(a,b,c){var d=$CLJS.J.j(this.ra,b,null);if($CLJS.n(d)){if($CLJS.E.g(d,c))return this;a=this.Na.h?this.Na.h(c):this.Na.call(null,c);d=this.Na.h?this.Na.h(d):this.Na.call(null,d);var e=$CLJS.J.g(this.ab,d);return $CLJS.E.g($CLJS.D(e),1)?new kz($CLJS.R.j($CLJS.Mj.g(this.ab,d),a,$CLJS.Yd.g($CLJS.J.j(this.ab,a,$CLJS.Sg),b)),$CLJS.R.j(this.ra,b,c),this.K,this.Na,null):new kz($CLJS.R.l(this.ab,d,$CLJS.Nj.g($CLJS.J.g(this.ab,d),b),$CLJS.H([a,$CLJS.Yd.g($CLJS.J.j(this.ab,a,$CLJS.Sg),b)])),
$CLJS.R.j(this.ra,b,c),this.K,this.Na,null)}a=this.Na.h?this.Na.h(c):this.Na.call(null,c);return new kz($CLJS.R.j(this.ab,a,$CLJS.Yd.g($CLJS.J.j(this.ab,a,$CLJS.Sg),b)),$CLJS.R.j(this.ra,b,c),this.K,this.Na,null)};$CLJS.g.Va=function(a,b){return $CLJS.Cd(this.ra,b)};
$CLJS.g.ga=function(){var a=this,b=this;return $CLJS.n(a.Na)?$CLJS.y(function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,A,C,G,K,S){return function da(T){return new $CLJS.ie(null,function(){return function(){for(;;){var oa=$CLJS.y(T);if(oa){if($CLJS.vd(oa)){var Ma=$CLJS.ic(oa),sb=$CLJS.D(Ma),Qa=$CLJS.le(sb);a:for(var Xa=0;;)if(Xa<sb){var Ja=$CLJS.hd(Ma,Xa);Ja=
new $CLJS.P(null,2,5,$CLJS.Q,[Ja,a.ra.h?a.ra.h(Ja):a.ra.call(null,Ja)],null);Qa.add(Ja);Xa+=1}else{Ma=!0;break a}return Ma?$CLJS.oe($CLJS.qe(Qa),da($CLJS.jc(oa))):$CLJS.oe($CLJS.qe(Qa),null)}Qa=$CLJS.z(oa);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[Qa,a.ra.h?a.ra.h(Qa):a.ra.call(null,Qa)],null),da($CLJS.Hc(oa)))}return null}}}(v,x,A,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}(a.ab)}()):$CLJS.y(function(){return function e(d){return new $CLJS.ie(null,
function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,A,C,G,K,S){return function da(T){return new $CLJS.ie(null,function(oa,Ma,sb){return function(){for(;;){var Qa=$CLJS.y(T);if(Qa){if($CLJS.vd(Qa)){var Xa=$CLJS.ic(Qa),Ja=$CLJS.D(Xa),Ua=$CLJS.le(Ja);a:for(var Ta=0;;)if(Ta<Ja){var mb=$CLJS.hd(Xa,Ta);Ua.add(new $CLJS.P(null,2,5,$CLJS.Q,[mb,sb],null));Ta+=1}else{Xa=!0;break a}return Xa?$CLJS.oe($CLJS.qe(Ua),da($CLJS.jc(Qa))):
$CLJS.oe($CLJS.qe(Ua),null)}Ua=$CLJS.z(Qa);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[Ua,sb],null),da($CLJS.Hc(Qa)))}return null}}}(v,x,A,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}(a.ab)}())};$CLJS.g.P=function(a,b){return new kz(this.ab,this.ra,b,this.Na,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.ud(b)?this.ma(null,$CLJS.hd(b,0),$CLJS.hd(b,1)):$CLJS.cb($CLJS.lb,this,b)};
$CLJS.g.call=function(a){switch(arguments.length-1){case 1:return this.h(arguments[1]);case 2:return this.g(arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length-1)].join(""));}};$CLJS.g.apply=function(a,b){return this.call.apply(this,[this].concat($CLJS.bb(b)))};$CLJS.g.h=function(a){return this.na(null,a)};$CLJS.g.g=function(a,b){return this.$(null,a,b)};
$CLJS.mz=new kz(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;a:for(b=$CLJS.y(0<b.length?new $CLJS.w(b.slice(0),0,null):null),d=$CLJS.jk;;)if(b)c=$CLJS.B($CLJS.B(b)),d=$CLJS.R.j(d,$CLJS.z(b),$CLJS.dd(b)),b=c;else break a;return d}(),$CLJS.N,$CLJS.N,$CLJS.Pd,null);$CLJS.J.g($CLJS.q($CLJS.jz),"tailrecursion.priority-map");
$CLJS.zh.v($CLJS.jz,$CLJS.R,"tailrecursion.priority-map",function(a){if($CLJS.sd(a))return $CLJS.Wf.g($CLJS.mz,a);throw Error("Priority map literal expects a map for its elements.");});