var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Qoa,ZZ,Roa,a_,b_,c_,z_,Uoa,Toa,Voa,Soa,F_,q_,H_,l_,Woa,s_;Qoa=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};ZZ=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Cd($CLJS.Yfa,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
Roa=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.vA,!0],null)),c=$CLJS.J.g(b,$CLJS.Xfa),d=$CLJS.J.g(b,$CLJS.vA);return $CLJS.y(a)?(b=$CLJS.zU(function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=ZZ(x,$CLJS.Ra(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae(ZZ(u,
$CLJS.Ra(d)),k($CLJS.Hc(l)))}return null}},null,null)}(Qoa($CLJS.aA(a)))}()),$CLJS.n(c)?$CLJS.zU($CLJS.We(c,b)):b):null};$CLJS.$Z=function(a){return $CLJS.iO.h($CLJS.z($CLJS.sK.h(a)))};a_=function(a,b){if(null!=a&&null!=a.Ag)a=a.Ag(a,b);else{var c=a_[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=a_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.table",a);}return a};
b_=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=b_[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=b_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.card",a);}return a};c_=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=c_[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=c_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.fields",a);}return a};
$CLJS.d_=function(a){return $CLJS.xY(a)?a:$CLJS.iD.h(a)};$CLJS.e_=function(a,b){return a_($CLJS.d_(a),b)};$CLJS.f_=function(a,b){return c_($CLJS.d_(a),b)};$CLJS.g_=function(a,b){return b_($CLJS.d_(a),b)};
$CLJS.i_=function(a,b,c,d,e){var f=$CLJS.O(e);e=$CLJS.J.j(f,$CLJS.eZ,$CLJS.h_);f=$CLJS.J.g(f,$CLJS.dZ);if($CLJS.n($CLJS.n(f)?f:$CLJS.Va(b)||$CLJS.sd(b))&&($CLJS.n(b.__mbcache)||(b.__mbcache=e.o?e.o():e.call(null)),b=b.__mbcache,$CLJS.n(b))){f=e.j?e.j(b,a,c):e.call(null,b,a,c);if($CLJS.n(f))return f;d=d.h?d.h(c):d.call(null,c);e.v?e.v(b,a,c,d):e.call(null,b,a,c,d);return d}return d.h?d.h(c):d.call(null,c)};
$CLJS.j_=function(a,b,c){return $CLJS.i_(a,b,b,c,new $CLJS.h(null,2,[$CLJS.eZ,$CLJS.h_,$CLJS.dZ,!1],null))};
z_=function(a,b,c){var d=$CLJS.k_.j(a,b,c);return $CLJS.lk.l($CLJS.H([$CLJS.Cl(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,$CLJS.mD,$CLJS.jj],null)),function(){var e=$CLJS.SV.h(c);return $CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.mD,e,l_,!0],null):null}(),function(){var e=$CLJS.m_.v(a,b,c,$CLJS.n_);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.o_,e],null):null}(),function(){var e=$CLJS.zz($CLJS.ki,$CLJS.tA)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.ki,e],null):null}(),function(){var e=$CLJS.bK.h(d);return $CLJS.n(e)?
(e=$CLJS.Ad(e)?$CLJS.e_(a,e):"string"===typeof e?$CLJS.g_(a,$CLJS.JV(e)):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.yj,$CLJS.p_.j(a,b,e)],null):null):null}(),function(){var e=$CLJS.MJ.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[q_,$CLJS.E.g(e,$CLJS.zY),$CLJS.r_,$CLJS.E.g(e,$CLJS.KY),s_,$CLJS.E.g(e,$CLJS.HY),$CLJS.t_,$CLJS.E.g(e,$CLJS.JY),Soa,$CLJS.E.g(e,$CLJS.lK),Toa,$CLJS.E.g(e,$CLJS.dK)],null):null}(),function(){var e=$CLJS.OY.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.u_,e],null)}(),function(){var e=
$CLJS.zz($CLJS.MV,$CLJS.rF)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.v_,$CLJS.Cd($CLJS.oG,e)&&!$CLJS.Cd($CLJS.nG,e)],null):null}(),$CLJS.Cl(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.w_,$CLJS.x_,$CLJS.y_],null))]))};$CLJS.h_=function h_(a){switch(arguments.length){case 0:return h_.o();case 3:return h_.j(arguments[0],arguments[1],arguments[2]);case 4:return h_.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.h_.o=function(){return $CLJS.Se($CLJS.N)};$CLJS.h_.j=function(a,b){return $CLJS.J.g($CLJS.q(a),b)};$CLJS.h_.v=function(a,b,c,d){return $CLJS.zh.v(a,$CLJS.R,b,d)};$CLJS.h_.A=4;$CLJS.A_=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.y_=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.B_=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.t_=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);
$CLJS.w_=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.C_=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.u_=new $CLJS.M(null,"selected","selected",574897764);Uoa=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);Toa=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);
Voa=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.D_=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);$CLJS.E_=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);Soa=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);
F_=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.G_=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);q_=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);H_=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);l_=new $CLJS.M(null,"named?","named?",-213401078);$CLJS.I_=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);
$CLJS.r_=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.v_=new $CLJS.M(null,"is-temporal-extraction","is-temporal-extraction",-1029608872);$CLJS.J_=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.x_=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);Woa=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.o_=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.K_=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.n_=new $CLJS.M(null,"long","long",-171452093);$CLJS.L_=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.M_=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);s_=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.N_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.yk,$CLJS.gB],null),$CLJS.yk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.O_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.yk,$CLJS.gB],null),$CLJS.yk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.m_=function(){function a(f,k,l,m){var t=$CLJS.zz($CLJS.mD,$CLJS.qD)($CLJS.GD(l));if($CLJS.n(t))return t;try{return $CLJS.N_.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.Th($CLJS.hD("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([l])),$CLJS.wU(k)])),new $CLJS.h(null,2,[$CLJS.HE,f,$CLJS.zD,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.Oh):e.call(null,f,k,l,$CLJS.Oh)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.P_=function(){function a(d,e,f){var k=$CLJS.U.h($CLJS.GD(f));if($CLJS.n(k))return k;try{return $CLJS.O_.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.Th($CLJS.hD("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([f])),$CLJS.wU(k)])),new $CLJS.h(null,3,[$CLJS.zD,f,$CLJS.HE,d,$CLJS.IK,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.N_.m(null,$CLJS.Oh,function(a,b,c){a=$CLJS.Mz($CLJS.Ex);$CLJS.n($CLJS.Lz("metabase.lib.metadata.calculation",a))&&$CLJS.Kz("metabase.lib.metadata.calculation",a,$CLJS.xV("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.xh.l($CLJS.H([c])),Voa,$CLJS.XA(c)])),null);return $CLJS.ud(c)&&$CLJS.z(c)instanceof $CLJS.M?$CLJS.Xg($CLJS.z(c)):$CLJS.xh.l($CLJS.H([c]))});
$CLJS.O_.m(null,$CLJS.Oh,function(a,b,c){return Roa($CLJS.Gz($CLJS.m_.j(a,b,c),/[\(\)]/,""))});$CLJS.Q_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.yk,$CLJS.gB],null),$CLJS.yk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.Xoa=function(){function a(d,e,f){return $CLJS.Q_.j(d,e,$CLJS.mh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.S_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.yk,$CLJS.gB],null),$CLJS.yk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.T_=function(){function a(d,e,f){var k=$CLJS.GD(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.rF),m=$CLJS.ki.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Cd($CLJS.oG,l):l)?$CLJS.Ti:null;if($CLJS.n(l))return l;k=$CLJS.tA.h(k);if($CLJS.n(k))return k;d=$CLJS.S_.j(d,e,f);return $CLJS.Dz(d,$CLJS.Xi)?d:$CLJS.Xi}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.S_.m(null,$CLJS.Oh,function(a,b,c){return $CLJS.ND(c)});$CLJS.S_.m(null,$CLJS.dE,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.T_.j(a,b,c)});$CLJS.S_.m(null,$CLJS.XH,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.ND(c):$CLJS.T_.j(a,b,d)});
$CLJS.U_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.yk,$CLJS.gB],null),$CLJS.yk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.U_.m(null,$CLJS.Oh,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.KJ,$CLJS.tA,$CLJS.T_.j(a,b,c),$CLJS.U,$CLJS.P_.j(a,b,c),$CLJS.mD,$CLJS.m_.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.Th($CLJS.hD("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([$CLJS.XA(c)])),$CLJS.wU(d)])),new $CLJS.h(null,3,[$CLJS.HE,a,$CLJS.IK,b,$CLJS.zD,c],null),d);}throw e;}});
$CLJS.k_=function(){function a(e,f,k){return $CLJS.U_.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.V_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.yk,$CLJS.gB],null),$CLJS.yk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.Y(F_,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.mj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.o_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.mj],null),new $CLJS.P(null,3,5,$CLJS.Q,[l_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,F_],null)],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[q_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.r_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[s_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.t_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[H_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Woa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Uoa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),$CLJS.mj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.mj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.mj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.L_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.mj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.J_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.u_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.B_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.br,$CLJS.iF,$CLJS.bF],null)],null)],null));
$CLJS.p_=function(){function a(d,e,f){return $CLJS.j_($CLJS.mh.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.V_.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.Th($CLJS.hD("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.XA(k),$CLJS.wU(l)])),new $CLJS.h(null,3,[$CLJS.HE,d,$CLJS.IK,e,$CLJS.zD,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.V_.m(null,$CLJS.Oh,function(a,b,c){return z_(a,b,c)});$CLJS.V_.m(null,$CLJS.SJ,function(a,b,c){return $CLJS.lk.l($CLJS.H([z_(a,b,c),new $CLJS.h(null,1,[H_,$CLJS.E.g($CLJS.$Z(a),$CLJS.Di.h(c))],null)]))});
$CLJS.W_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.yk,$CLJS.gB],null),$CLJS.yk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();$CLJS.W_.m(null,$CLJS.wA,function(){return $CLJS.xf});
$CLJS.X_=function(){function a(f,k,l,m){m=$CLJS.lk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.I_,$CLJS.LV()],null),m]));return $CLJS.W_.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.IV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.Y_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.yk,$CLJS.gB],null),$CLJS.yk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();$CLJS.Y_.m(null,$CLJS.wA,function(){return $CLJS.xf});
$CLJS.Y_.m(null,$CLJS.Oh,function(a,b,c,d){return $CLJS.W_.v(a,b,c,d)});
$CLJS.Z_=function(){function a(f,k,l,m){m=$CLJS.lk.l($CLJS.H([$CLJS.lk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.I_,$CLJS.LV()],null),new $CLJS.h(null,4,[$CLJS.G_,!0,$CLJS.M_,!0,$CLJS.C_,!0,$CLJS.K_,!0],null)])),m]));return $CLJS.Y_.v(f,k,l,m)}function b(f,k,l){return $CLJS.n(function(){var m=$CLJS.sd(l);if(m){m=$CLJS.Vs.h(l);var t=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.eV,null,$CLJS.YU,null],null),null);return t.h?t.h(m):t.call(null,m)}return m}())?$CLJS.j_($CLJS.mh.h([$CLJS.p.h(k),"__visible-columns-no-opts"].join("")),
f,function(){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}):e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.IV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
module.exports={display_name:$CLJS.m_};